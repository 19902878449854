<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 15"
  >
    <g>
      <path
        fill="currentColor"
        d="M8.5,8.5h-7c-1.3,0-2,1.6-1.1,2.6l3.5,3.5c0.6,0.6,1.5,0.6,2.1,0l3.5-3.5C10.5,10.1,9.8,8.5,8.5,8.5z M5,13.5 L1.5,10h7L5,13.5z"
      />
      <path
        fill="currentColor"
        d="M0.6,6.2c0.2,0.2,0.5,0.3,0.9,0.3h7c0.3,0,0.6-0.1,0.9-0.3c0.7-0.5,0.9-1.6,0.2-2.3L6.1,0.4C5.8,0.1,5.4,0,5,0 S4.2,0.1,3.9,0.4L0.5,3.9C-0.2,4.6-0.1,5.7,0.6,6.2z"
      />
    </g>
  </svg>
</template>
